<template>
  <SharedMembershipBox class="bg-white" :price="price" title="haelsi Rundum Plus" :link="link" :type="type">
    <MembershipBaseList />
    <UiHorizontalLineWithCircle class="py-2" color="gray" />
    <ul class="flex flex-col gap-2">
      <AppListItem type="checkmark" class="text-sm text-gray-600">
        <span class="font-medium">Premium Vorsorgecheck</span> inkludiert
      </AppListItem>
      <AppListItem type="checkmark" class="text-sm text-gray-600">
        <span class="font-medium">30% Rabatt</span> auf alle Honorare bei haelsi Therapeut:innen
      </AppListItem>
    </ul>
  </SharedMembershipBox>
</template>

<script setup lang="ts">
import { Membership } from "~/utils/enum/Membership";
import { createMembershipLink, getMembershipPrice } from "~/utils/membership";

const props = withDefaults(
  defineProps<{
    type?: "monthly" | "yearly";
    quickCheckout?: boolean;
  }>(),
  {
    quickCheckout: true,
    type: "yearly",
  },
);

const price = getMembershipPrice(Membership.RUNDUM_PLUS, props.type);
const link = createMembershipLink(Membership.RUNDUM_PLUS, props.type, props.quickCheckout);
</script>

<style scoped></style>
