<template>
  <ul class="flex flex-col gap-2">
    <AppListItem type="checkmark" class="text-sm text-gray-600">
      <span class="font-medium">25% Rabatt</span> auf jeden Arztbesuch
    </AppListItem>
    <AppListItem type="checkmark" class="text-sm text-gray-600">
      <span class="font-medium">15% Rabatt</span> auf haelsi Supplements
    </AppListItem>
    <AppListItem type="checkmark" class="text-sm text-gray-600">
      <span class="font-medium">Hohe Rückerstattung:</span> Bis zu 45&nbsp;&#037; deiner Arztrechnungen übernimmt die
      Krankenkasse
    </AppListItem>
    <AppListItem type="checkmark" class="text-sm text-gray-600">
      <span class="font-medium">Patientenportal:</span> Sicherer Zugriff auf deine Gesundheitsdokumente - immer und von
      überall
    </AppListItem>
    <AppListItem type="checkmark" class="text-sm text-gray-600">
      <span class="font-medium">Member-Support:</span> Priorität bei Terminen, via Telefon &amp; E-Mail
    </AppListItem>
  </ul>
</template>

<script setup lang="ts"></script>

<style scoped></style>
