<template>
  <div class="inline">
    <LazySharedDrawer ref="drawer" @close="onClose">
      <div class="flex flex-col gap-4">
        <h3 class="text-2xl leading-tight">haelsi Vorteilsmitgliedschaft</h3>
        <template v-if="price && reducedPrice && savings && discount">
          <div
            class="flex cursor-pointer flex-col rounded-2xl border border-gray-200 bg-brick-50 p-4 transition duration-300 ease-in-out hover:border-brick hover:bg-brick-100"
            @click="handleSavingsCardClick">
            <div class="flex items-center justify-between">
              <h4 class="text-sm text-brick-700">dein Termin</h4>
              <AppPill class="self-start bg-green-100 font-medium text-green-700">Spare {{ discount }}&nbsp;%</AppPill>
            </div>
            <div class="flex items-center gap-2">
              <p class="text-2xl font-medium text-gray-700">{{ transformToCurrency(reducedPrice) }}</p>
              <p class="font-medium text-red-600 line-through">{{ transformToCurrency(price) }}</p>
            </div>
            <p class="text-sm text-green-600">{{ transformToCurrency(savings) }} gespart</p>
            <p class="mt-2 text-sm">
              Du bist dir nicht sicher? Überzeuge dich auf der
              <AppLink to="/abo?mtm_campaign=quick-checkout" target="_blank">Mitgliedschaft Seite</AppLink>.
            </p>
          </div>
        </template>

        <template v-if="isKidsAppointment === false && isTherapyAppointment === false">
          <MembershipBasic type="yearly" />
          <MembershipRundum type="yearly" />
        </template>
        <template v-if="isTherapyAppointment || isAppointment === false">
          <MembershipPlus type="yearly" />
          <MembershipRundumPlus type="yearly" />
        </template>
        <template v-if="isKidsAppointment || isAppointment === false">
          <MembershipBasicKids type="yearly" />
          <MembershipPlusKids type="yearly" />
        </template>
      </div>
    </LazySharedDrawer>
  </div>
</template>

<script setup lang="ts">
const membershipDrawerStore = useMembershipDrawerStore();
const price = computed(() => membershipDrawerStore.price);
const discount = computed(() => membershipDrawerStore.discount);
const isKidsAppointment = computed(() => isPediatricsDepartment(membershipDrawerStore.department));
const isTherapyAppointment = computed(() => hasHigherDiscount(membershipDrawerStore.department));
const isAppointment = computed(() => membershipDrawerStore.department !== undefined);

const reducedPrice = computed(() =>
  price.value && discount.value ? roundToPrecision(price.value * (1 - discount.value / 100), 2) : undefined,
);
const savings = computed(() =>
  price.value && reducedPrice.value ? roundToPrecision(price.value - reducedPrice.value, 2) : undefined,
);

membershipDrawerStore.$subscribe(
  (_mutation, state) => {
    if (state.isVisible === true) {
      handleOpen();
    }
  },
  { detached: true },
);
const drawer = ref();
function handleOpen(): void {
  drawer.value?.open();
}
function onClose(): void {
  membershipDrawerStore.close();
}
async function handleSavingsCardClick(event: MouseEvent): Promise<void> {
  event.preventDefault();
  await navigateTo("/abo?mtm_campaign=quick-checkout", {
    open: {
      target: "_blank",
    },
  });
}
</script>

<style scoped></style>
