<template>
  <SharedMembershipBox
    class="border-yellow-600 bg-yellow-50"
    :price="price"
    title="haelsi Rundum"
    :link="link"
    :type="type"
    :highlight="true">
    <MembershipBaseList />
    <UiHorizontalLineWithCircle class="py-2" color="yellow" />
    <ul class="flex flex-col gap-2">
      <AppListItem type="checkmark" class="text-sm text-gray-600">
        <span class="font-medium">Premium Vorsorgecheck</span> inkludiert
      </AppListItem>
    </ul>
  </SharedMembershipBox>
</template>

<script setup lang="ts">
import { Membership } from "~/utils/enum/Membership";
import { createMembershipLink, getMembershipPrice } from "~/utils/membership";

const props = withDefaults(
  defineProps<{
    type?: "monthly" | "yearly";
    quickCheckout?: boolean;
  }>(),
  {
    quickCheckout: true,
    type: "yearly",
  },
);

const price = getMembershipPrice(Membership.RUNDUM, props.type);
const link = createMembershipLink(Membership.RUNDUM, props.type, props.quickCheckout);
</script>

<style scoped></style>
